import { ComponentStyleConfig } from '@chakra-ui/react';

export const Result: ComponentStyleConfig = {
  parts: ['total', 'detail', 'totalValue', 'value', 'label', 'hint'],
  baseStyle: {
    total: {
      alignItems: 'flex-end',
      justifyContent: 'space-between',
      lineHeight: 1,
      py: 2,
    },
    detail: {
      alignItems: 'flex-end',
      justifyContent: 'space-between',
    },
    totalValue: {
      fontSize: { base: '4xl', md: '6xl' },
      fontWeight: 900,
    },
    value: {
      fontWeight: 900,
    },
    label: {
      color: 'gray.300',
      fontWeight: 'normal',
    },
    hint: {
      color: 'gray.300',
      fontSize: '0.7rem',
      fontStyle: 'italic',
      fontWeight: 'normal',
      lineHeight: 1.3,
    },
  },
};
