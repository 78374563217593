export const API = {
  base: '/',
  getPlaces: {
    base: '/getPlaces',
  },
  getPlaneDistance: {
    base: '/getPlaneDistance',
  },
  getSeaDistance: {
    base: '/getSeaDistance',
  },
  getPorts: {
    base: '/getPorts',
  },
};
