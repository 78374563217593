import { extendTheme } from '@chakra-ui/react';
import { theme as baseTheme } from '@chakra-ui/theme';
import { Autocomplete } from './Autocomplete';
import { Button } from './Button';
import { Divider } from './Divider';
import { Form } from './Form';
import { FormLabel } from './FormLabel';
import { Input } from './Input';
import { Radio } from './Radio';
import { Result } from './Result';
import { Title } from './Title';

export const theme = extendTheme(
  {
    breakpoints: {
      lg: '64em',
    },
    styles: {
      global: {
        body: {
          background: 'white',
          color: 'blue.500',
          fontSize: 'md',
          fontWeight: 'semibold',
        },
      },
    },
    layerStyles: {
      blue: {
        background: 'blue.400',
        color: 'white',
      },
      lightBlue: {
        background: 'blue.50',
        color: 'blue.500',
      },
      column: {
        px: { base: 6, md: 16 },
        pt: { base: 8, md: 10 },
        pb: 12,
      },
      thin: {
        alignSelf: 'center',
        boxSizing: 'content-box',
        maxWidth: 460,
        px: 6,
        width: `calc(100% - ${baseTheme.space[6]} * 2)`,
      },
      sublabel: {
        color: 'blue.100',
        fontSize: 'sm',
        fontWeight: 'normal',
      },
      disabledText: {
        color: 'gray.300',
      },
    },
    colors: {
      blue: {
        50: '#EFF9FD',
        100: '#C3E8F7',
        200: '#ABE2F7',
        300: '#9BD9F2',
        400: '#83D0EF',
        500: '#002C60',
        600: '#001F43',
      },
      gray: {
        300: '#9AA9AF',
      },
      yellow: {
        300: '#E7D790',
      },
    },
    fonts: {
      heading: 'Montserrat',
      body: 'Montserrat',
    },
    components: {
      Autocomplete,
      Button,
      Divider,
      Form,
      Input,
      FormLabel,
      Radio,
      Result,
      Title,
    },
  },
  baseTheme,
);
