import {DeleteIcon} from '@chakra-ui/icons';
import {
  Box,
  Button,
  ChakraComponent,
  Divider,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  HTMLChakraProps,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import {t as translate} from 'i18next';
import {FunctionComponent, useCallback, useEffect, useState,} from 'react';
import {FieldError, useForm} from 'react-hook-form';
import {useTranslation} from "react-i18next";
import {useDebounce} from 'use-debounce';
import type {ApiPlacesResponse, ApiPortsResponse, Place} from '../types';
import {Emission, Transport} from '../types';
import {ILocation} from '../types/Location';
import {HTTP} from '../utils';
import {API} from '../utils/API';
import {calculateEmission} from '../utils/calculateEmission';
import {getCoordinatesByPlaceName} from '../utils/getPlaceIdByName';
import {AutoComplete} from './Autocomplete';

const DEBOUNCE_DELAY = 300;

export interface IFormValues {
  originPort: string;
  originPlace: string;
  selectedOriginPort?: Place | null;
  selectedOriginPlace?: Place | null;
  destinationPort: string;
  destinationPlace: string;
  selectedDestinationPort?: Place | null;
  selectedDestinationPlace?: Place | null;
  transport: Transport;
  weight: number | '';
}

interface IProps extends HTMLChakraProps<'form'> {
  setEmission: (emission: Emission | Error) => void;
}

export const Form: FunctionComponent<IProps> = ({
  setEmission,
  ...formProps
}) => {
  const FormBox = Box as ChakraComponent<'form'>;
  const styles = useMultiStyleConfig('Form', formProps);

  const [sessionToken, setSessionToken] = useState<string | null>(null);

  const [showOriginPlace, setShowOriginPlace] = useState(false);
  const [showDestinationPlace, setShowDestinationPlace] = useState(false);

  const [isOriginPortLoading, setOriginPortLoading] = useState(false);
  const [isOriginPlaceLoading, setOriginPlaceLoading] = useState(false);
  const [isDestinationPortLoading, setDestinationPortLoading] = useState(false);
  const [isDestinationPlaceLoading, setDestinationPlaceLoading] =
    useState(false);

  const [originPortList, setOriginPortList] = useState<Place[] | null>(null);
  const [originPlaceList, setOriginPlaceList] = useState<Place[] | null>(null);
  const [destinationPortList, setDestinationPortList] = useState<
    Place[] | null
  >(null);
  const [destinationPlaceList, setDestinationPlaceList] = useState<
    Place[] | null
  >(null);

  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    register,
    resetField,
    setValue,
    watch,
  } = useForm<IFormValues>({
    reValidateMode: 'onSubmit',
    shouldFocusError: false,
    shouldUseNativeValidation: false,
    defaultValues: {
      transport: Transport.Air,
      originPlace: '',
      destinationPlace: '',
      originPort: '',
      destinationPort: '',
      weight: '',
    },
  });

  const {
    transport,
    originPlace,
    selectedOriginPlace,
    destinationPlace,
    selectedDestinationPlace,
    originPort,
    selectedOriginPort,
    destinationPort,
    selectedDestinationPort,
  } = watch();

  const [debouncedOriginPortSearch] = useDebounce(originPort, DEBOUNCE_DELAY);
  const [debouncedOriginPlaceSearch] = useDebounce(originPlace, DEBOUNCE_DELAY);
  const [debouncedDestinationPortSearch] = useDebounce(
    destinationPort,
    DEBOUNCE_DELAY,
  );
  const [debouncedDestinationPlaceSearch] = useDebounce(
    destinationPlace,
    DEBOUNCE_DELAY,
  );

  const onSubmit = async (values: IFormValues) => {
    let originPortLocation: ILocation | undefined =
      values.selectedOriginPort?.location;
    let destinationPortLocation: ILocation | undefined =
      values.selectedDestinationPort?.location;

    if (
      values.selectedOriginPlace &&
      values.selectedOriginPort &&
      !originPortLocation
    ) {
      console.log('Origin Coordinates missing...');

      originPortLocation = await getCoordinatesByPlaceName(
        values.selectedOriginPort.name,
        sessionToken ?? undefined,
      );
    }

    if (
      values.selectedDestinationPlace &&
      values.selectedDestinationPort &&
      !destinationPortLocation
    ) {
      console.log('Destination Coordinates missing...');
      destinationPortLocation = await getCoordinatesByPlaceName(
        values.selectedDestinationPort.name,
        sessionToken ?? undefined,
      );
    }

    if (
      values.selectedOriginPort &&
      values.selectedDestinationPort &&
      (originPortLocation || !values.selectedOriginPlace) &&
      (destinationPortLocation || !values.selectedDestinationPlace)
    ) {
      try {
        setEmission(
          await calculateEmission(
            values.transport,
            parseFloat(values.weight.toString()),
            values.selectedOriginPort.name,
            values.selectedDestinationPort.name,
            originPortLocation?.lat,
            originPortLocation?.lng,
            destinationPortLocation?.lat,
            destinationPortLocation?.lng,
            sessionToken ?? undefined,
            values.selectedOriginPlace?.googlePlaceId,
            values.selectedDestinationPlace?.googlePlaceId,
          ),
        );
      } catch (error: any) {
        setEmission(error as Error);
      }
    }
  };

  const placeToString = (place: Place | null) => place?.name ?? '';

  const getPlaces = useCallback(
    async (query: string) => {
      if (query !== '') {
        const response = await HTTP().get<ApiPlacesResponse>(
          API.getPlaces.base,
          {
            params: {
              search: query,
              sessionToken,
            },
          },
        );
        if (response.status === 200) {
          const result: ApiPlacesResponse = await response.data;
          setSessionToken(result.sessionToken);

          return result.predictions.map((item) => ({
            id: item.place_id,
            name: item.description,
            googlePlaceId: item.place_id,
          })) as Place[];
        }
      }
      return null;
    },
    [sessionToken, setSessionToken],
  );

  const getPorts = useCallback(
    async (query: string) => {
      if (query !== '') {
        const response = await HTTP().get<ApiPortsResponse>(API.getPorts.base, {
          params: {
            input: query,
            type: transport,
          },
        });
        if (response.status === 200) {
          const result: ApiPortsResponse = await response.data;

          return result.ports as Place[];
        }
      }
      return null;
    },
    [transport],
  );

  const resetAutocompletes = useCallback(() => {
    resetField('originPort');
    resetField('originPlace');
    resetField('destinationPort');
    resetField('destinationPlace');
    setValue('selectedOriginPort', null);
    setValue('selectedOriginPlace', null);
    setValue('selectedDestinationPort', null);
    setValue('selectedDestinationPlace', null);
    setOriginPortList(null);
    setOriginPlaceList(null);
    setDestinationPortList(null);
    setDestinationPlaceList(null);
  }, [resetField, setValue]);

  const resetOriginPlace = useCallback(() => {
    setShowOriginPlace(false);
    resetField('originPlace');
    setValue('selectedOriginPlace', null);
    setOriginPlaceList(null);
    setDestinationPlaceList(null);
  }, [resetField, setValue, setShowOriginPlace]);

  const resetDestinationPlace = useCallback(() => {
    setShowDestinationPlace(false);
    resetField('destinationPlace');
    setValue('selectedDestinationPlace', null);
  }, [resetField, setValue, setShowDestinationPlace]);

  const renderError = useCallback(() => {
    let errorMessage = '';

    Object.keys(errors).forEach((index) => {
      const error = errors[index as keyof IFormValues] as FieldError;

      if (error.type === 'required') {
        errorMessage = translate('Please fill out the highlighted fields.');
      } else {
        errorMessage = error.message ?? '';
      }
    });
    return errorMessage;
  }, [errors]);

  useEffect(() => {
    setOriginPortLoading(true);

    getPorts(debouncedOriginPortSearch)
      .then((ports) => {
        setOriginPortList(ports);
      })
      .finally(() => {
        setOriginPortLoading(false);
      });
  }, [debouncedOriginPortSearch, getPorts]);

  useEffect(() => {
    setDestinationPortLoading(true);

    getPorts(debouncedDestinationPortSearch)
      .then((ports) => {
        setDestinationPortList(ports);
      })
      .finally(() => {
        setDestinationPortLoading(false);
      });
  }, [debouncedDestinationPortSearch, getPorts]);

  useEffect(() => {
    setOriginPlaceLoading(true);

    getPlaces(debouncedOriginPlaceSearch)
      .then((places) => {
        setOriginPlaceList(places);
      })
      .finally(() => {
        setOriginPlaceLoading(false);
      });
  }, [debouncedOriginPlaceSearch, getPlaces]);

  useEffect(() => {
    setDestinationPlaceLoading(true);
    getPlaces(debouncedDestinationPlaceSearch)
        .then((places) => {
          setDestinationPlaceList(places);
        })
        .finally(() => {
          setDestinationPlaceLoading(false);
        });
  }, [debouncedDestinationPlaceSearch, getPlaces]);

  const [t] = useTranslation('common');

  return (
      <FormBox
          as="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={styles.form}
          {...formProps}
      >
        <Stack spacing={10}>
          <Heading as="h2" fontSize="xl" fontWeight={700}>
          {t('Your calculation:')}
        </Heading>
        <Stack spacing={5}>
          <FormControl isInvalid={!!errors.transport}>
            <FormLabel htmlFor="transport">{t('How do you want to ship?')}</FormLabel>
            <RadioGroup accessor="transport" defaultValue={Transport.Air}>
              <Stack spacing={7} direction="row">
                <Radio
                  value={Transport.Air}
                  {...register('transport', {
                    onChange: resetAutocompletes,
                  })}
                >
                  {t('Air')}
                </Radio>
                <Radio
                  value={Transport.Sea}
                  {...register('transport', {
                    onChange: resetAutocompletes,
                  })}
                >
                  {t('Sea')}
                </Radio>
              </Stack>
            </RadioGroup>
          </FormControl>
          <Divider />
          <Stack spacing={2}>
            <AutoComplete
              label={t("Origin")}
              accessor="originPort"
              register={register}
              error={errors.originPort}
              items={originPortList}
              inputValue={originPort}
              isLoading={isOriginPortLoading}
              itemToString={placeToString}
              onSelectItem={(item) => {
                if (item) {
                  setValue('originPort', placeToString(item));
                  setValue('selectedOriginPort', item);
                }
              }}
              validation={{
                required: t('Please fill out the origin port.'),
                validate: () => {
                  if (
                    selectedOriginPort == null ||
                    selectedOriginPort.name !== originPort
                  ) {
                    return t('You need to select a valid origin port.');
                  }

                  return true;
                },
              }}
            />
            {!showOriginPlace && (
              <Button
                variant="formlink"
                onClick={() => setShowOriginPlace(true)}
              >
                {t('Add Pre Carriage')}
              </Button>
            )}
          </Stack>
          {showOriginPlace && (
            <HStack align={{ base: 'flex-end', md: 'center' }}>
              <AutoComplete
                label={
                  <>
                    {
                      // TODO: discuss how to display the translation here 
                       } 
                    Pre Carriage{' '}
                    <Text as="span" layerStyle="sublabel">
                      (Road)
                    </Text>
                  </>
                }
                accessor="originPlace"
                register={register}
                error={errors.originPlace}
                poweredByGoogle
                items={originPlaceList}
                inputValue={originPlace}
                isLoading={isOriginPlaceLoading}
                itemToString={placeToString}
                onSelectItem={(item) => {
                  if (item) {
                    setValue('originPlace', placeToString(item));
                    setValue('selectedOriginPlace', item);
                  }
                }}
                validation={{
                  validate: () => {
                    if (
                      selectedOriginPlace == null ||
                      selectedOriginPlace.name !== originPlace
                    ) {
                      return t('You need to select a valid origin place.');
                    }

                    return true;
                  },
                }}
              />
              <Box>
                <IconButton
                  icon={<DeleteIcon />}
                  variant="delete"
                  aria-label="Delete"
                  onClick={resetOriginPlace}
                />
              </Box>
            </HStack>
          )}
          <Divider />
          <Stack spacing={2}>
            <AutoComplete
              label={t("Destination")}
              accessor="destinationPort"
              register={register}
              error={errors.destinationPort}
              items={destinationPortList}
              inputValue={destinationPort}
              isLoading={isDestinationPortLoading}
              itemToString={placeToString}
              onSelectItem={(item) => {
                if (item) {
                  setValue('destinationPort', placeToString(item));
                  setValue('selectedDestinationPort', item);
                }
              }}
              validation={{
                required: t('Please fill out the destination port.'),
                validate: () => {
                  if (
                    selectedDestinationPort == null ||
                    selectedDestinationPort.name !== destinationPort
                  ) {
                    return t('You need to select a valid destination port.');
                  }

                  return true;
                },
              }}
            />
            {!showDestinationPlace && (
              <Button
                variant="formlink"
                onClick={() => setShowDestinationPlace(true)}
              >
                {t('Add On Carriage')}
              </Button>
            )}
          </Stack>
          {showDestinationPlace && (
            <HStack align={{ base: 'flex-end', md: 'center' }}>
              <AutoComplete
                label={
                  <>
                    {
                      // TODO: discuss how to display the translation here 
                    }
                    On Carriage{' '}
                    <Text as="span" layerStyle="sublabel">
                      (Road)
                    </Text>
                  </>
                }
                accessor="destinationPlace"
                register={register}
                error={errors.destinationPlace}
                poweredByGoogle
                items={destinationPlaceList}
                inputValue={destinationPlace}
                isLoading={isDestinationPlaceLoading}
                itemToString={placeToString}
                onSelectItem={(item) => {
                  if (item) {
                    setValue('destinationPlace', placeToString(item));
                    setValue('selectedDestinationPlace', item);
                  }
                }}
                validation={{
                  validate: () => {
                    if (
                      selectedDestinationPlace == null ||
                      selectedDestinationPlace.name !== destinationPlace
                    ) {
                      return t('You need to select a valid destination place.');
                    }

                    return true;
                  },
                }}
              />
              <Box>
                <IconButton
                  icon={<DeleteIcon />}
                  variant="delete"
                  aria-label="Delete"
                  onClick={resetDestinationPlace}
                />
              </Box>
            </HStack>
          )}
          <Divider />
          <FormControl isInvalid={!!errors.weight} alignItems="center">
            <FormLabel htmlFor="weight" flexBasis={{ md: 130 }}>
              {t('Weight')}
            </FormLabel>
            <Box flexGrow={2}>
              <InputGroup>
                <Input
                  type="number"
                  step="any"
                  id="weight"
                  variant="filled"
                  {...register('weight', {
                    required: t('Please fill out the cargo weight'),
                    min: {
                      value: 0,
                      message: t('Please enter a valid weight.'),
                    },
                    max: {
                      value: 9_999_999,
                      message: t('Please enter a valid weight.'),
                    },
                  })}
                />
                <InputRightElement children={<Text>kg</Text>} />
              </InputGroup>
            </Box>
          </FormControl>
        </Stack>
        <Button
          type="submit"
          isLoading={isSubmitting}
          variant="solid"
          colorScheme="blue"
          isFullWidth
        >
          {t('Calculate now')}
        </Button>
      </Stack>
      {Object.keys(errors).length > 0 && (
        <Box sx={styles.error}>
          <Text>{renderError()}</Text>
        </Box>
      )}
    </FormBox>
  );
};
