import { ApiPlacesResponse } from '../types';
import { ILocation } from '../types/Location';
import { API } from './API';
import { HTTP } from './HTTP';

const getPlaceIdByName = async (placeName: string, sessionToken?: string) => {
  const response = await HTTP().get<ApiPlacesResponse>(API.getPlaces.base, {
    params: {
      search: placeName,
      sessionToken,
    },
  });
  if (response.status === 200) {
    return response.data.predictions[0]?.place_id;
  }
};

export const getCoordinatesByPlaceName = async (
  placeName: string,
  sessionToken?: string,
): Promise<ILocation | undefined> => {
  const placeId = await getPlaceIdByName(placeName, sessionToken);

  const placeDetails = await HTTP().get(API.getPlaces.base, {
    params: {
      placeId,
      sessionToken,
    },
  });
  if (placeDetails.status === 200) {
    return placeDetails.data.result.geometry?.location;
  }
};
