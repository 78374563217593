import '@fontsource/montserrat';
import '@fontsource/montserrat/500.css';
import '@fontsource/montserrat/600.css';
import '@fontsource/montserrat/700.css';
import '@fontsource/montserrat/900.css';

import { Button, HStack, Link, Stack, Text } from '@chakra-ui/react';
import { FunctionComponent, useState } from 'react';
import {useTranslation} from "react-i18next";
import { Form } from './components/Form';
import { Header } from './components/Header';
import { Result } from './components/Result';
import { Title } from './components/Title';
import { Emission } from './types';

export const App: FunctionComponent = () => {
  const [emission, setEmission] = useState<Emission | Error | null>(null);
  const [t] = useTranslation('common');

    return (
    <Stack spacing={0}>
      <Header />
      <Stack position="relative" layerStyle="blue">
        <Title layerStyle={{ base: 'lightBlue', lg: 'blue' }} />
        <Stack>
          <Form layerStyle={['column', 'thin']} setEmission={setEmission} />
        </Stack>
      </Stack>
      <Stack>
        {emission instanceof Error ? (
          <Stack layerStyle={['column', 'thin']} spacing={10}>
            <Text fontWeight="normal">{emission.message}</Text>
          </Stack>
        ) : (
          emission && (
            <Stack layerStyle={['column', 'thin']} spacing={10}>
              <Result result={emission} />
            </Stack>
          )
        )}
      </Stack>
      <Stack layerStyle="lightBlue">
        <Stack layerStyle={['column', 'thin']} spacing={10}>
          <Text align="center">
              {t('Can we ship something for you? Follow this link and get your instant quotation now!')}
          </Text>
          <Button
            as="a"
            variant="solid"
            colorScheme="blue"
            href="https://my.rohlig.com/"
            target="_blank"
            isFullWidth
          >
              {t('Get your quote')}
          </Button>
        </Stack>
      </Stack>
      <Stack>
        <Stack layerStyle={['column', 'thin']} spacing={10}>
          <HStack
            justify="center"
            textAlign="center"
            spacing={{ base: 8, md: 10 }}
            fontSize="xs"
          >
            <Link href="https://www.rohlig.com/imprint">Imprint</Link>
            <Link href="https://www.rohlig.com/data-privacy">Data Privacy</Link>
            <Link href="https://www.rohlig.com/terms-and-conditions">
              Conditions of Usage
            </Link>
          </HStack>
        </Stack>
      </Stack>
    </Stack>
  );
};
