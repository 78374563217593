import {ChakraProvider} from '@chakra-ui/react';
import i18next from "i18next";
import languageDetector from "i18next-browser-languagedetector";
import React from 'react';
import ReactDOM from 'react-dom';
import {I18nextProvider, initReactI18next} from "react-i18next";
import {App} from './App';
import {theme} from './theme';
import common_no from './translations/no/common.json';
import common_sv from './translations/sv/common.json';

const detectionOptions = {
    order: ['navigator', 'path', 'cookie', 'localStorage', 'subdomain', 'queryString', 'htmlTag'],
    lookupFromPathIndex: 0

}

i18next
    .use(languageDetector)
    .use(initReactI18next)
    .init({
        ns: 'common',
        defaultNS: "common",
        detection: detectionOptions,
        interpolation: {escapeValue: false},  // React already does escaping
        resources: {
            no: {
                common: common_no
            },
            sv: {
                common: common_sv
            }
        },
        fallbackLng: "en", // use en if detected lng is not available
    }, (err) => {
        if (err)
            console.error(err)
    });

ReactDOM.render(
    <React.StrictMode>
        <ChakraProvider theme={theme}>
            <I18nextProvider i18n={i18next}>
                <App/>
            </I18nextProvider>
        </ChakraProvider>
    </React.StrictMode>,
    document.getElementById('root'),
);
