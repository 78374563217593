import { ComponentStyleConfig } from '@chakra-ui/react';

export const Radio: ComponentStyleConfig = {
  baseStyle: {
    control: {
      _checked: {
        _hover: {
          background: 'white',
          borderColor: 'white',
        },
        background: 'white',
        borderColor: 'white',
      },
    },
  },
};
