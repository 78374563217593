import { ComponentStyleConfig } from '@chakra-ui/react';

export const Input: ComponentStyleConfig = {
  variants: {
    filled: {
      field: {
        background: 'blue.300',
        borderColor: 'transparent',
        borderRadius: 2,
        borderWidth: 2,
        fontWeight: 700,
        px: 4,
        py: 2,
        _focus: {
          background: 'blue.200',
          borderColor: 'white',
        },
        _hover: {
          background: 'blue.200',
        },
        _invalid: {
          _focus: {
            borderColor: 'yellow.300',
          },
          borderColor: 'yellow.300',
        },
      },
    },
  },
};
