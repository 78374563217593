import axios from 'axios';

const getBasePath = () => {
  if (process.env.REACT_APP_API_BASE_PATH == null) {
    return '';
  }

  if (!process.env.REACT_APP_API_BASE_PATH.startsWith('/')) {
    return `/${process.env.REACT_APP_API_BASE_PATH}`;
  }

  return process.env.REACT_APP_API_BASE_PATH;
};

export const HTTP = (timeout?: number) =>
  axios.create({
    baseURL: `${process.env.REACT_APP_API_BASE_URL}${getBasePath()}`,
    timeout: timeout ?? 30000,
  });
