import { ComponentStyleConfig } from '@chakra-ui/react';

export const Title: ComponentStyleConfig = {
  parts: ['box', 'heading'],
  baseStyle: {
    box: {
      pl: { base: 6, md: 16 },
      pr: { base: 6, md: 16 },
      py: { base: 8, md: 10 },
    },
    heading: {
      fontSize: '3xl',
      fontWeight: 900,
      lineHeight: 1.1,
    },
    subtitle: {
      display: 'block',
      fontSize: '2xl',
      fontWeight: 600,
      textTransform: 'uppercase',
    },
  },
  variants: {
    sticky: {
      box: {
        textAlign: 'left',
        position: 'absolute',
        pl: 16,
        pr: 0,
        left: 0,
        top: 0,
        zIndex: 10,
      },
      heading: {
        fontSize: '7xl',
      },
      subtitle: {
        fontSize: '3xl',
      },
    },
  },
};
