import { ComponentStyleConfig } from '@chakra-ui/react';

export const Form: ComponentStyleConfig = {
  baseStyle: {
    form: {
      position: 'relative',
    },
    container: {
      display: { md: 'flex' },
      justifyContent: 'space-between',
      flexDirection: 'row',
    },
    requiredIndicator: {
      color: 'inherit',
    },
    error: {
      bottom: 0,
      left: 0,
      mx: { base: 6, md: 16 },
      position: 'absolute',
      px: { base: 5, md: 10 },
      right: 0,
      transform: 'translateY(50%)',

      '> *': {
        backgroundColor: 'yellow.300',
        fontSize: 'sm',
        textAlign: 'center',
        py: 1,
      },
    },
  },
};
