import { ComponentStyleConfig } from '@chakra-ui/react';

export const Autocomplete: ComponentStyleConfig = {
  parts: ['dropdown', 'spinner', 'list', 'listItem', 'noResult'],
  baseStyle: {
    dropdown: {
      backgroundColor: 'blue.300',
      borderRadius: 2,
      display: 'flex',
      marginTop: 1,
      maxHeight: '300px',
      position: 'absolute',
      shadow: '0 14px 28px 0 rgba(160, 160, 160, 0.3)',
      width: '100%',
      zIndex: 1000,
    },
    spinner: {
      display: 'block',
      mx: 'auto',
      my: 3,
    },
    list: {
      overflowY: 'auto',
      width: '100%',
    },
    listItem: {
      cursor: 'pointer',
      fontSize: 'sm',
      px: 4,
      py: 2,

      _hover: {
        backgroundColor: 'blue.200',
      },
    },
    noResult: {
      fontSize: 'sm',
      fontWeight: 'normal',
      px: 4,
      py: 2,
    },
  },
  variants: {
    google: {
      dropdown: {
        backgroundImage: 'images/powered-by-google.png',
        backgroundPosition: 'bottom 4px right 4px',
        backgroundRepeat: 'no-repeat',
        paddingBottom: '20px',
      },
    },
  },
};
