import {
  Image,
  Link,
  HStack,
  StackProps,
  ThemingProps,
  Text,
} from '@chakra-ui/react';
import { FunctionComponent } from 'react';

export const Header: FunctionComponent<StackProps & ThemingProps> = ({
  ...props
}) => (
  <HStack justify="space-between" layerStyle="column" {...props}>
    <Image
      src="images/roehlig-logistics-logo.png"
      alt="Röhlig Logistics"
      width={110}
    />
    <Link href="https://www.rohlig.com/" fontSize="sm">
      Röhlig Website{' '}
      <Text as="span" fontWeight="normal">
        &#10132;
      </Text>
    </Link>
  </HStack>
);
